import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, Grid, Button, Paper, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloudUpload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { ClassicSpinner } from "react-spinners-kit";
import axios from "axios";
import { BASE_URL } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";

// Validation schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  companyName: Yup.string().required("Company Name is required"),
  abnNumber: Yup.string().required("ABN Number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string().required("Mobile is required"),
});

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 800,
  margin: "0 auto",
  backgroundColor: "#fff",
  borderRadius: 12,
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
}));

const UploadBox = styled(Box)(({ theme }) => ({
  border: "2px dashed #ccc",
  borderRadius: 8,
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor: "#f8f8f8",
  },
}));

const MyForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const siteKey = "6Ldo3KMpAAAAAPiBdeVIIYopfndok0OTiUAsHAcr";
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("uid", "3c4bb38ca4488df90ae444761f8ad411");
      formData.append("service", "Sharepoint");

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Append files
      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post(
        `${BASE_URL}/external/perform_solution/quote_creation`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      enqueueSnackbar("Response Submitted Successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      resetForm();
      setFiles([])
    } catch (error) {
      const data = error.response.data.message;
      setLoading(false);
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const removeFile = (index) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <StyledPaper>
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        sx={{ mb: 4, fontWeight: "bold" }}
      >
        Contact Information
      </Typography>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          companyName: "",
          abnNumber: "",
          email: "",
          mobile: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  as={TextField}
                  label="First Name"
                  fullWidth
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  as={TextField}
                  label="Last Name"
                  fullWidth
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyName"
                  as={TextField}
                  label="Company Name"
                  fullWidth
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="abnNumber"
                  as={TextField}
                  label="ABN Number"
                  fullWidth
                  error={touched.abnNumber && Boolean(errors.abnNumber)}
                  helperText={touched.abnNumber && errors.abnNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="email"
                  as={TextField}
                  label="Email"
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="mobile"
                  as={TextField}
                  label="Mobile"
                  fullWidth
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <UploadBox>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <Button
                      component="span"
                      startIcon={<CloudUpload />}
                      variant="outlined"
                      sx={{ mb: 2 }}
                    >
                      Upload Files
                    </Button>
                  </label>
                  <Box sx={{ mt: 2 }}>
                    {files.map((file, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          p: 1,
                          border: "1px solid #eee",
                          borderRadius: 1,
                          mb: 1,
                        }}
                      >
                        <Typography variant="body2">{file.name}</Typography>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => removeFile(index)}
                        >
                          Remove
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </UploadBox>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA sitekey={siteKey} onChange={handleCaptchaChange} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!captchaVerified || loading}
                    sx={{
                      minWidth: 200,
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                    }}
                  >
                    {loading ? (
                      <ClassicSpinner size={20} color="#fff" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <ClassicSpinner size={30} color="#1976d2" />
        </Box>
      )}
    </StyledPaper>
  );
};

export default MyForm;
