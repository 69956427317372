export const apiHdrDefValue = {
    APPLICATION_JSON: "application/json",
    REFERRED_BY: "Web",
    FORM_URLENCODED: "application/x-www-form-urlencoded",
    FORM_DATA: "multipart/form-data",
};

export const apiHttpStatus = {
    SC_200: 200,
    SC_201: 201,
    SC_202: 202,
    SC_400: 400,
    SC_403: 403,
    SC_412: 412,
    SC_424: 424,
    SC_401: 401,
};

export const apiEndPoint = {
    USER_ROLE_FETCHER: "/user/",
    GET_ALL_USERS: "/users/",
    ADMIN_LOGIN: "/login",
    USER_BRANDING_DATA:"/branding/",
    CUSTOMER_DATA:"/customer/",
    CUSTOMERS_DATA:"/customers/",
    DASHBOARD:"/dashboard",
};

